<template>
  <v-tooltip v-bind="$attrs" v-on="$listeners">
    <template v-slot:activator="{ attrs, on }">
      <v-icon
        :disabled="disabled"
        :color="iconColorClass"
        @click="onClick"
        v-bind="attrs"
        v-on="on"
        >{{ iconName }}</v-icon
      >
    </template>
    <span>
      <slot name="default">{{ tooltipText }}</slot>
    </span>
  </v-tooltip>
</template>

<script>
import { eventNames } from "@/model/common/events/eventConst";

export default {
  name: "BaseTooltipIcon",
  inheritAttrs: false,
  props: {
    iconName: {
      type: String,
      default: undefined
    },
    iconColorClass: {
      type: String,
      default: undefined
    },
    tooltipText: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick(e) {
      this.$emit(eventNames.click, e);
    }
  }
};
</script>
